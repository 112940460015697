import type { TypeStockCategory } from "scripts/purchase/types";
import { storable } from "scripts/utils/commonStates";
import type { TypeTableConfig } from "scripts/utils/types";
import { writable, type Writable } from "svelte/store";


export const slipItemForStockTableConfig: Writable<TypeTableConfig> = writable({
    pk: { label: 'ID', type: 'number', editable: false, hidden: true },
    date: {
        label: '日付',
        type: 'datetime',
        editable: false,
    },
    slip_type: {
        label: '種類',
        type: 'choices',
        editable: false,
    },
    amount_changed: {
        label: '変化量',
        type: 'amount_changed',
        editable: false,
    },
    quantity: {
        label: '数量',
        type: 'float',
        editable: false,
    },

    change_reason: {
        label: '変更理由',
        type: 'string',
        editable: false,
    },
    user: {
        label: 'user',
        type: 'string',
        editable: false
    }
})

export const stockTableConfig: Writable<TypeTableConfig> = writable({
    pk: { label: 'ID', type: 'number', editable: false, hidden: true },
    thumbnail: { label: '画像', type: 'image', editable: false, hidden: true },
    code: { label: '在庫コード', type: 'string', editable: false },
    category: { label: 'カテゴリ', type: 'choices', editable: false },
    stock_type: { label: '取扱区分', type: 'choices', editable: false, hidden: true },
    name: {
        label: '名称',
        type: 'string',
        editable: false,
        style: 'text-align: left;'
    },
    cost: {
        label: '原価',
        type: 'number',
        editable: false,
        hidden: true
    },
    quantity: {
        label: '個数',
        type: 'number',
        editable: false,
    },
    unit: {
        label: '単位',
        type: 'string',
        editable: false,
        hidden: true,
    },
    supplier: {
        label: '業者',
        type: 'choices',
        editable: false,
    },
})

export const stockCategoryTableConfig: Writable<TypeTableConfig> = writable({
    id: { label: 'ID', type: 'number', editable: false, hidden: true },
    name: {
        label: '名前',
        type: 'string',
        editable: false
    },
    index: {
        label: '表示順',
        type: 'string',
        editable: false,
    },
    company: {
        label: '所有者',
        type: 'string',
        editable: false,
        hidden: true
    }
})


export const stockerPageCondition = storable({
    activeTab: '在庫',
    searchParamObj: {}
}, 'stocker')