<script lang="ts">
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import ModalShell from 'scripts/global_components/ModalShell.svelte';
  import ModelDetailForm from 'scripts/global_components/ModelDetailForm.svelte';
  import type { TypeStockCategory } from 'scripts/purchase/types';
  import { routeStockerApp } from 'scripts/router';
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import { makeConfigFromOptions } from 'scripts/utils/form_utils';
  import type {
    ChoiceDisplayMap,
    FormConfig,
    SimpleFormConfig,
  } from 'scripts/utils/types';
  import { onMount } from 'svelte';
  import { stockCategoryTableConfig } from './store';

  export let spinnerDisplay = false;
  let endpoint: string = routeStockerApp.stockCategoryApi();
  let itemList = [];
  let choiceDisplayMap: ChoiceDisplayMap = {};

  const initialStockCategory: TypeStockCategory = {
    id: null,
    name: '',
    index: 999,
    company: '',
  };
  let stockCategory = { ...initialStockCategory };
  let open = false;
  let formConfig: FormConfig = {};
  let simpleFormConfig: SimpleFormConfig = {
    Row_1: {
      name: {
        inputClass: 'col-12',
      },
      index: {
        inputClass: 'col-12 col-md-8',
      },
    },
  };

  onMount(() => {
    makeDataSets();
  });

  const makeDataSets = async () => {
    spinnerDisplay = true;
    const configRes = await makeConfigFromOptions(
      endpoint,
      simpleFormConfig,
      $stockCategoryTableConfig,
    );
    formConfig = configRes.formConfig;
    $stockCategoryTableConfig = configRes.tableConfig;
    choiceDisplayMap = configRes.choiceDisplayMap;
    const res = await fetchGetRequest({
      endpoint: endpoint,
      errorMessage: '在庫カテゴリーの取得に失敗しました。',
      params: {},
    });
    if (res.success) {
      itemList = res.data;
      spinnerDisplay = false;
    } else {
      spinnerDisplay = false;
    }
  };
  const onTrClick = (item: TypeStockCategory) => {
    console.log(item);
    stockCategory = item;
    open = true;
  };

  const onProcessEnd = () => {
    open = false;
    stockCategory = { ...initialStockCategory };
    makeDataSets();
  };
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<div class="row p-2">
  <div class="col-12">
    <EditableDataTable
      on:trClick={(e) => {
        onTrClick(e.detail.item);
      }}
      items={itemList}
      bind:tableConfig={$stockCategoryTableConfig}
      bind:choiceDisplayMap
      primaryKey="id"
      rowsPerPage={100}
    />
  </div>
</div>
<div class="row my-4">
  <div class="col-12 d-flex justify-content-center">
    <ModalShell
      bind:open
      btnLabel="新規カテゴリー登録"
      title="在庫カテゴリー登録"
      size="middle"
    >
      <ModelDetailForm
        modelName="在庫カテゴリー"
        bind:formConfig
        {endpoint}
        bind:state={stockCategory}
        primaryKey="id"
        on:processEnd={onProcessEnd}
      />
    </ModalShell>
  </div>
</div>
