<script lang="ts">
  import { fetchCustomRequest, fetchGetRequest } from 'scripts/utils/fetcher';
  import type {
    ChoiceDisplayMap,
    TableStatus,
    TypeTableConfig,
  } from 'scripts/utils/types';
  import { onMount } from 'svelte';
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import { pathArr, routeStockerApp } from 'scripts/router';
  import { slipItemForStockTableConfig } from './store';
  import { getAndAppendOptions } from 'scripts/utils/form_utils';
  import CustomTabs from 'scripts/global_components/CustomTabs.svelte';
  import { createNumArray } from 'scripts/utils/number_utils';
  import Spinner from 'scripts/global_components/child_components/Spinner.svelte';
  import { downloadBlob, objToString } from 'scripts/utils/utils';

  let talbeStatus: TableStatus;
  let tableConfig: TypeTableConfig = $slipItemForStockTableConfig;
  let tableEndpoint: string = routeStockerApp.slipItemApi();
  let itemList = [];
  let coloredRow = [];
  let choiceDisplayMap: ChoiceDisplayMap = {};
  let currentYear: number = new Date().getFullYear();
  const startYear = 2023;
  let yearList = createNumArray(
    startYear,
    1,
    Number(currentYear) - startYear + 1,
  );
  let spinnerDisplay = false;
  let stockCode = '';

  onMount(() => {
    makeDataSets();
    stockCode = pathArr.slice(-1)[0];
  });

  const makeDataSets = async () => {
    const configRes = await getAndAppendOptions(
      routeStockerApp.slipItemApi(),
      {},
      $slipItemForStockTableConfig,
    );
    if (configRes.success) {
      $slipItemForStockTableConfig = configRes.data.tableConfig;
      choiceDisplayMap = configRes.data.choiceDisplayMap;
    }
  };

  const getItemList = async (currentYear) => {
    const res = await fetchGetRequest({
      endpoint: tableEndpoint,
      errorMessage: 'の取得に失敗しました。',
      params: {
        stock__code: pathArr[2],
        date__range: [
          `${currentYear}-01-01 00:00+09:00`,
          `${currentYear + 1}-01-01 00:00+09:00`,
        ],
      },
    });
    if (res.success) itemList = res.data;
  };

  const getColoredRow = (itemList) => {
    coloredRow = itemList.filter((item) =>
      ['keep', 'wait'].includes(item.slip_type),
    );
  };

  const navDetail = (e) => {
    if (e.detail.item.detail_url) location.href = e.detail.item.detail_url;
  };

  /** 在庫管理ラベルダウンロードファンクション ボタンはstock_detail.htmlにあり。 */
  const stockLabelDownload = async () => {
    spinnerDisplay = true;
    const downloaLabelEp = routeStockerApp.downloadStockLabel(stockCode);
    // console.log(downloaLabelEp);

    const res = await fetchCustomRequest({
      endpoint: downloaLabelEp,
      method: 'POST',
    });
    if (res.success) downloadBlob(res.success, 'stock_label.svg');
    else {
      alert(objToString(res.error, ['status']));
    }
    if (res) spinnerDisplay = false;
  };
  const dwButton: HTMLButtonElement = document.querySelector('#export_label');
  dwButton.onclick = () => {
    stockLabelDownload();
  };

  $: getColoredRow(itemList);
  $: getItemList(currentYear);
  // $: console.log(coloredRow);
</script>

<div class="ps-3 mb-5">
  <hr class="my-4" />
  <p class="font-weight-bolder text-muted">入出庫履歴</p>
  <CustomTabs bind:active={currentYear} tabs={yearList}>
    <EditableDataTable
      bind:items={itemList}
      bind:tableConfig
      bind:choiceDisplayMap
      primaryKey="pk"
      displayUpdateButton={false}
      bind:coloredRow
      on:trClick={navDetail}
    />
  </CustomTabs>
</div>
<Spinner display={spinnerDisplay} />
