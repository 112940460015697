<script lang="ts">
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import { routeConfig, routeStockerApp } from 'scripts/router';
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import type {
    ChoiceDisplayMap,
    FormConfig,
    SearchParamObj,
    SimpleFormConfig,
  } from 'scripts/utils/types';
  import {
    applyInitialValue,
    makeConfigFromOptions,
    searchParamToQueryParams,
  } from 'scripts/utils/form_utils';
  import { onMount } from 'svelte';
  import {
    stockTableConfig,
    stockerPageCondition,
  } from 'scripts/stocker/store';
  import ModelDetailForm from 'scripts/global_components/ModelDetailForm.svelte';
  import ModalShell from 'scripts/global_components/ModalShell.svelte';
  import type { TypeStock } from 'scripts/purchase/types';
  import AdvancedSearchForm from 'scripts/global_components/child_components/AdvancedSearchForm.svelte';
  import { rebuildSearchParamObj } from 'scripts/utils/commonStates';

  let itemList = [];
  let choiceDisplayMap: ChoiceDisplayMap = {};
  const stockEp = routeStockerApp.stockForStockerApi();
  let open = false;
  let searchParamObj: SearchParamObj = {};
  let searchFields = [];

  export let spinnerDisplay = false;
  const initialStock: TypeStock = {
    code: '',
    qr_value: '',
    stock_type: 'P',
    name: '',
    cost: 0,
    supplier: null,
    unit: '',
    thumbnail: '',
    image: null,
    stock_place: '',
    note: '',
  };
  let stock = { ...initialStock };

  onMount(() => {
    getDataSets();
  });

  let formConfig: FormConfig = {};

  let simpleFormConfig: SimpleFormConfig = {
    Row_1: {
      code: {
        inputClass: 'col-12 col-md-4',
      },
      qr_value: {
        inputClass: 'col-12 col-md-4',
      },
      stock_type: {
        inputClass: 'col-12 col-md-4',
      },
      supplier: {
        inputClass: 'col-12 col-md-4',
      },
      category: {
        inputClass: 'col-12 col-md-6',
      },
    },
    Row_2: {
      name: {
        inputClass: 'col-12 col-md-12',
      },
    },
    Row_3: {
      cost: {
        inputClass: 'col-12 col-md-4',
      },
      quantity: {
        inputClass: 'col-12 col-md-4',
      },
      unit: {
        inputType: 'image',
        inputClass: 'col-12 col-md-4',
      },
    },
    Row_4: {
      stock_place: {
        inputClass: 'col-12',
      },
      image: {
        inputClass: 'col-12',
        inputType: 'file',
        initialValue: null,
      },
    },
    Row_5: {
      note: {
        inputClass: 'col-12',
        inputStyle: 'min-height: 4rem;',
        inputType: 'string',
        initialValue: '',
      },
    },
  };

  const getDataSets = async () => {
    spinnerDisplay = true;
    const configRes = await makeConfigFromOptions(
      stockEp,
      simpleFormConfig,
      $stockTableConfig,
    );
    formConfig = configRes.formConfig;
    $stockTableConfig = configRes.tableConfig;
    choiceDisplayMap = configRes.choiceDisplayMap;
    $stockTableConfig.supplier.hidden = false;
    $stockTableConfig.thumbnail.hidden = false;
    const rebuilt = rebuildSearchParamObj(
      configRes.searchParamObj,
      $stockerPageCondition.searchParamObj,
      searchFields,
    );
    searchParamObj = rebuilt.searchParamObj;
    searchFields = rebuilt.searchFields;
    applyInitialValue(formConfig, stock);
    getStocks();
  };

  const getStocks = async () => {
    const res = await fetchGetRequest({
      endpoint: stockEp,
      errorMessage: '在庫の取得に失敗しました。',
      params: searchParamToQueryParams(
        $stockerPageCondition.searchParamObj,
        searchFields,
      ),
    });
    if (res.success) {
      itemList = res.data;
      spinnerDisplay = false;
    } else {
      spinnerDisplay = false;
    }
  };

  const onProcessEnd = () => {
    open = false;
    stock = { ...initialStock };
    getDataSets();
  };

  const onAdavancedSearch = (event) => {
    console.log(event.detail);

    $stockerPageCondition.searchParamObj = event.detail;
    getStocks();
  };

  // $: console.log(queryParams);
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<div class="row p-2">
  <div class="col-12">
    <AdvancedSearchForm
      bind:searchParamObj
      bind:searchFields
      {formConfig}
      hiddenFields={['image']}
      apiEndpoint={stockEp}
      detailEndpoint={routeStockerApp.stockDetailPage()}
      primaryKey={'code'}
      on:searchClick={onAdavancedSearch}
    />
    <EditableDataTable
      on:trClick={(e) =>
        (location.href = `/stocker/detail/${e.detail.item.code}/`)}
      items={itemList}
      bind:tableConfig={$stockTableConfig}
      bind:choiceDisplayMap
      primaryKey="pk"
    />
  </div>
</div>
<div class="row my-4">
  <div class="col-12 d-flex justify-content-center">
    <ModalShell bind:open btnLabel="新規在庫登録" title="在庫登録" size="large">
      <ModelDetailForm
        modelName="在庫"
        bind:formConfig
        endpoint={stockEp}
        bind:state={stock}
        primaryKey="pk"
        on:processEnd={onProcessEnd}
      />
    </ModalShell>
    <a href={routeConfig.neRedirect} class="btn btn-success mx-3">NE在庫同期</a>
  </div>
</div>
